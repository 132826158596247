import React from 'react';
import { graphql, PageProps } from 'gatsby';
import { ContentfulCommonPage } from '../contentful';
import { RichText } from '../components/rich-text';
import { Typography } from '@mui/material';
import { PageBlocks } from '../components/page-blocks';
import { Helmet } from 'react-helmet';

interface CommonPagesQueryProps {
  contentfulPageCommon: ContentfulCommonPage;
}

type CommonPagesType = PageProps<CommonPagesQueryProps>;

const CommonPages = ({ data }: CommonPagesType) => {
  const components = data.contentfulPageCommon.components;

  return (
    <>
      <Helmet title={data.contentfulPageCommon.title} />
      <div className="content">
        <Typography variant="h1" component="h1" sx={{ my: 4 }}>
          {data.contentfulPageCommon.title}
        </Typography>

        {data.contentfulPageCommon.intro && (
          <RichText
            data={{
              richText: {
                raw: data.contentfulPageCommon.intro.raw,
                references: [],
              },
            }}
          />
        )}
      </div>
      {components &&
        components.map((component) => (
          <PageBlocks data={component} key={component.id} />
        ))}
    </>
  );
};

export const query = graphql`
  query ($slug: String!) {
    contentfulPageCommon(slug: { eq: $slug }) {
      ...CommonPage
    }
  }
`;

export default CommonPages;
